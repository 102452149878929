import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import DataPrivacyView from './data-privacy-view'

const DataPrivacyController = () => {
  const [loading, setLoading] = useState(false)
  const [dataPrivacy, setDataPrivacy] = useState({})
  const [dataFiltered, setDataFiltered] = useState([])
  const { i18n } = useTranslation()
  const getdataPrivacyAssistance = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'page',
      params: {
        slug: 'data-privacy',
        locale: i18n.language,
      },
    })
      .then(({ data }) => {
        setDataPrivacy(data)
        setDataFiltered(_.get(data, 'content[0]'))
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onHandelFilterByGroup = (group) => {
    const dataByFilter = _.head(_.filter(dataPrivacy.content, { slug: group }))
    setDataFiltered(dataByFilter)
  }

  useEffect(() => {
    if (process.env.GATSBY_HAS_DATA_PRIVACY) {
      getdataPrivacyAssistance()
    } else {
      navigate('/404/')
    }
  }, [i18n.language])

  const viewProps = {
    data: dataPrivacy,
    loading,
    onHandelFilterByGroup,
    dataFiltered,
  }
  return <DataPrivacyView {...viewProps} />
}

export default DataPrivacyController
